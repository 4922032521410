import axiosClient from "../axios-client";

export const getFromApi = async (endpoint, params) => {
  try {
    const result = await axiosClient.get(endpoint, {
      params,
      // withCredentials: false,
    });
    return result?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const sendToApi = async (endpoint, data, method) => {
  try {
    let result;
    if (method === "POST") result = await axiosClient.post(endpoint, data);
    else if (method === "PUT") result = await axiosClient.put(endpoint, data);
    else if (method === "PATCH") result = await axiosClient.patch(endpoint, data);
    else if (method === "DELETE")
      result = await axiosClient.delete(endpoint, {
        params: data,
      });
    return result;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Cannot send request");
  }
};
