export const API = {
  // ----------------- User --------------------
  login: "/api/users/login",
  creat_auth: "/auth/jwt/create/",
  membersList: "/api/users/",
  addUser: "/api/users/create/",
  pendingRegistration:
    "/api/transactions/?owner__profile__profile_active=PENDING&owner__profile__kyc_approved=APPROVED&trans_status=PENDING&trans_reason=REGISTRATION",
  pendingKYCRegistration: "/api/users/?profile__kyc_approved=PENDING",
  updateUser: (id) => `/api/users/${id}/update/`,
  companyInterest: "/api/usertrans",
  customCompanyInterest: (id) => `/api/usertrans/?months=${id}`,
  askChangePassword: "/auth/users/reset_password/",
  setNewPassword: "/auth/users/reset_password_confirm/",
  userListCommission:
    "/api/users/?profile__profile_active=APPROVED&profile__kyc_approved=APPROVED",

  // ----------------- Transactions --------------------
  transactions: "/api/transactions/",
  transactionsInterest: "/api/transactions/?trans_reason=COMMISSION",
  updateTransactions: (id) => `/api/transactions/${id}/update/`,
  monthlyReturn: "/api/transactions/monthly/?trans_status=APPROVED&ordering=-trans_date",
  dailyReturn: "/api/transactions/daily/?trans_status=APPROVED",
  totalReturn: "/api/transactions/total/",
  joiningsMonthly: "/api/joining_date_count/monthly/",
  customJoiningsMonthly: (id) =>
    `/api/joining_date_count/monthly/?months=${id}`,
  customJoiningsDaily: `/api/joining_date_count/daily/?months=1`,
  createCommission: "api/transactions/createcommission_trans/",
  createPersonalizedCommission:
    "api/transactions/createcommission_trans_personalized/",
  createNewsLeteter: "/api/createnewsletter/",
  newsLetter: "/api/newsletters",
  deleteNewsletter: (id) => `/api/newsletter/${id}`,
};
