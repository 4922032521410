import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";

export const useAddPersonalizedCommission = ({ callBackOnSuccess }) =>
  usePostData({
    hideSuccessToast:true,
    endpoint: API.createPersonalizedCommission,
    queryKeysToInvalidate: [["commissions"]],
    callBackOnSuccess,
  });
