import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListNewsletter = () => {
  return useReadData({
    queryKey: [["newsletter"]],
    endpoint: API.newsLetter,
  });
};

export { useListNewsletter };
