import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useCustomUserTransList = (id) => {
  return useReadData({
    queryKey: [["comapnyInterest"+id]],
    endpoint: API.customCompanyInterest(id),
  });
};

export { useCustomUserTransList };
