import "./payout.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import ButtonGroup from "@mui/joy/ButtonGroup";
import React, { useState } from "react";
import DateTimeDisplay from "../../components/date-time-display.js";
import { useListTransactions } from "../../api/transactions/use-list-transactions.js";
import toast from "react-hot-toast";
import { useEditTransaction } from "../../api/transactions/use-edit-transaction.js";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
function areEqual(num1, num2) {
  return num1 === num2;
}

function Payout() {
  const [transactionId, setTransactionId] = useState(0);
  const [filterStatus, setFilterStatus] = useState("PENDING");
  const [filterUserName, setFilterUserName] = useState("");
  const [filterPaymentGateway, setFilterPaymentGateway] = useState("");
  const [open, setOpen] = React.useState(false);
  const [reasonForReject, setReasonForReject] = React.useState("");
  const { mutate: updateTransaction, isPending } = useEditTransaction({
    id: transactionId,
    callBackOnSuccess: () => {
      window.location.reload()
    },
  });
  const handleApprove = async (pk) => {
    setTransactionId(pk);
    updateTransaction({ trans_status: "APPROVED" });
  };
  const handleReject = async () => {
    // setTransactionId(pk);
    updateTransaction({
      trans_status: "REJECTED",
      trans_rejection_note: reasonForReject,
    });
  };

  const { data: transactions } = useListTransactions();

  const filteredTransactions = transactions?.filter((row) => {
    const matchespayout = row.trans_reason.includes("PAYOUT");
    const matchesUserName = row.username
      .toLowerCase()
      .includes(filterUserName.toLowerCase());
    const matchesPayment = row.trans_mode
      .toLowerCase()
      .includes(filterPaymentGateway.toLowerCase());
    const matchesStatus = row.trans_status.includes(filterStatus);
    return matchesStatus && matchesPayment && matchespayout && matchesUserName;
  });
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">Payout </div>
        <Stack spacing={1} direction="row" style={{ marginBottom: "10px" }}>
          <Input
            placeholder="Username"
            value={filterUserName}
            onChange={(e) => setFilterUserName(e.target.value)}
          />
          <Input
            placeholder="Reason"
            value={filterPaymentGateway}
            onChange={(e) => setFilterPaymentGateway(e.target.value)}
          />
          <Button
            onClick={() => {
              setFilterUserName("");
              setFilterPaymentGateway("");
              toast.success("Filters cleared");
            }}
          >
            Reset
          </Button>
        </Stack>

        <ButtonGroup
          style={{
            backgroundColor: "white",
            marginBottom: "10px",
            width: "fit-content",
          }}
          variant="plain"
          aria-label="plain button group"
        >
          <Button
            onClick={() => setFilterStatus("PENDING")}
            variant={areEqual("PENDING", filterStatus) ? "solid" : "plain"}
          >
            Requests
          </Button>
          <Button
            onClick={() => setFilterStatus("APPROVED")}
            variant={areEqual("APPROVED", filterStatus) ? "solid" : "plain"}
          >
            Approved
          </Button>
          <Button
            onClick={() => setFilterStatus("REJECTED")}
            variant={areEqual("REJECTED", filterStatus) ? "solid" : "plain"}
          >
            Declined
          </Button>
        </ButtonGroup>

        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="manually-styled-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Payment amount (USD)</th>
                <th>Payment amount (BTC)</th>
                <th>Payment method</th>
                <th>Reason</th>
                <th>E-wallet balance</th>
                <th
                  className={areEqual(filterStatus, "PENDING") ? "" : "hidden"}
                  aria-label="last"
                  style={{ width: "var(--Table-lastColumnWidth)" }}
                />
              </tr>
            </thead>
            <tbody>
              {filteredTransactions?.map((row) => (
                <tr key={row.pk}>
                  <td>
                    <DateTimeDisplay apiDateTime={row.trans_date} />
                  </td>
                  <td>{row.username} </td>
                  <td>{row.first_name} </td>
                  <td>{row.last_name} </td>
                  <td>{row.trans_amt_usd} USD</td>
                  <td>{row.trans_amt_btc} BTC</td>
                  <td>{row.trans_mode}</td>
                  <td>{row.trans_reason}</td>
                  <td>{row.balance}</td>
                  <td
                    className={
                      areEqual("PENDING", filterStatus) ? "" : "hidden"
                    }
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={() => {
                          setTransactionId(row.pk);
                          handleApprove(row.pk);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        size="sm"
                        variant="soft"
                        color="danger"
                        onClick={() => {
                          setTransactionId(row.pk);
                          // handleReject(row.pk);

                          setOpen(true);
                        }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Reject payout?
          </Typography>
          Please input the reason for this rejection
          <Input
            placeholder="Type in here…"
            onChange={(e) => {
              setReasonForReject(e.target.value);
            }}
          />
          <br />
          <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
            <Button
              size="sm"
              variant="soft"
              color="primary"
              onClick={() => {
                setOpen(false);
                setReasonForReject("");
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="soft"
              color="danger"
              onClick={() => {
                setOpen(false);
                handleReject();
              }}
            >
              Reject
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </div>
  );
}

export default Payout;
