import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListCustomJoiningsMonthly = (id) => {
  return useReadData({
    queryKey: [["monthlyJoinings"+id]],
    endpoint: API.customJoiningsMonthly(id),
  });
};

export { useListCustomJoiningsMonthly };
