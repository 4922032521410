import "./overview-tile-1.css";
import { useState, useEffect } from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from "@mui/joy/Button";
import { useNavigate } from "react-router-dom";
import DateTimeDisplay from '../../components/date-time-display.js';
import { useCompanyInterestList } from "../../api/members-list/use-list-company-interest.js";
import { useCustomUserTransList } from "../../api/members-list/use-list-personalized-user-trans.js";

function OverviewTileOne() {
  const navigate = useNavigate(); // Get the navigate function
  const [selectedValue, setSelectedValue] = useState("max");
  const { data: customCompanyInterestOneMonth } = useCustomUserTransList("1");
  const { data: customCompanyInterestSixMonths } = useCustomUserTransList("6");
  const { data: allCompanyInterest } = useCompanyInterestList();

  const [companyInterest, setCompanyInterest] = useState(null);

  // console.log(companyInterest)
  const handleChange = (value) => {
    setSelectedValue(value);
    if (value === "6") {
      setCompanyInterest(customCompanyInterestSixMonths);
    } else if (value == "1") {
      setCompanyInterest(customCompanyInterestOneMonth);

    } else {
      setCompanyInterest(allCompanyInterest);

    }
  };
  useEffect(() => {
    if (allCompanyInterest) {
      setCompanyInterest(allCompanyInterest);
    }
  }, [allCompanyInterest]);
  return (
    <div>
      <div
        className="subtitle-and-dropdown"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="subtitle">Company interest (on deposit)</div>
        <div style={{ marginLeft: "auto" }}>
          <Select defaultValue="max" onChange={(e, newValue) => handleChange(newValue)}>
            <Option value="1">One month</Option>
            <Option value="6">Six months</Option>
            <Option value="max">Max</Option>
          </Select>
        </div>
      </div>
      <div className="summary-cards-three">
        <div className="summary-card-shadowed">
          New joiners
          <div className="smaller-text-blue">{companyInterest ? companyInterest[0].new_joiners_count.count_new_joiners : ""}</div>
        </div>
        <div className="summary-card-shadowed">
          Total deposits
          <div className="smaller-text-blue">{companyInterest ? companyInterest[0].total_agg_amt.sum_registration_investment_usd_approved?.toFixed(0) : ""} USD</div>
        </div>
        <div className="summary-card-shadowed">
          Total interest
          <div className="smaller-text-blue">{companyInterest ? companyInterest[0].total_agg_amt.sum_interest_usd?.toFixed(0) : ""} USD</div>
        </div>
      </div>


      <table style={{ width: "100%" }}>
        <thead >
          <tr>
            <th>New Members</th>
            <th>Joining date</th>
            <th>Deposits</th>
            <th>Interests</th>
          </tr>
        </thead>
        <tbody>
          {companyInterest?.slice(0, 3).map((row) => (
            <tr key={row.id}>
              <td>{row.user.username}</td>
              <td><DateTimeDisplay length={"short"} apiDateTime={row.user.date_joined} /></td>
              <td>{row.agg_amt?.sum_registration_investment_reinjection_usd_approved ? row.agg_amt.sum_registration_investment_reinjection_usd_approved?.toFixed(0) : 0}$</td>
              <td>{row.agg_amt?.sum_commission_usd_approved ? row.agg_amt.sum_commission_usd_approved?.toFixed(0) : 0}$</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="button-left align-bottom">
        <Button
          color="primary"
          variant="soft"
          size="lg"
          onClick={() => {
            navigate("/e-wallet");
          }}
        >
          Go to E-Wallet
        </Button>
      </div>

    </div>)
}

export default OverviewTileOne;