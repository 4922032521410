import "./overview.css";
import { useState, useEffect } from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import EnhancedTable from "../table/table.js";
import Button from "@mui/joy/Button";
import { useNavigate } from "react-router-dom";
import { useListTotalReturn } from "../../api/transactions/use-list-total-return.js";
import { useListJoiningsMonthly } from "../../api/transactions/use-list-joinings-monthly.js";
import OverviewTileOne from "../overview-tile-1/overview-tile-1.js";
import { useListCustomJoiningsMonthly } from "../../api/transactions/use-list-custom-joinings-monthly.js";
import { useListCustomJoiningsDaily } from "../../api/transactions/use-list-custom-joinings-daily.js";

function Overview() {
  const navigate = useNavigate();
  const { data: totalReturn } = useListTotalReturn();
  const { data: allMonthlyJoinings } = useListJoiningsMonthly();
  const { data: customMonthlyJoiningsOneMonth } = useListCustomJoiningsDaily("30");
  const { data: customMonthlyJoiningsSixMonths } = useListCustomJoiningsMonthly("6");

  const [monthlyJoinings, setMonthlyJoinings] = useState(null);

  const handleChange = (value) => {
    setSelectedValue(value);
    if (value === "6") {
      setMonthlyJoinings(customMonthlyJoiningsSixMonths);
      // console.log(customMonthlyJoiningsSixMonths)
    } else if (value == "1") {
      setMonthlyJoinings(customMonthlyJoiningsOneMonth);
      // console.log(customMonthlyJoiningsOneMonth)
    } else {
      setMonthlyJoinings(allMonthlyJoinings);
      // console.log(allMonthlyJoinings)

    }
  };
  useEffect(() => {
    if (allMonthlyJoinings) {
      setMonthlyJoinings(allMonthlyJoinings);
    }
  }, [allMonthlyJoinings]);

  const uData = monthlyJoinings?.map((item) => item.count_new_joiners);
  const xLabels = monthlyJoinings?.map((item) => item.joining_date);
  const [selectedValue, setSelectedValue] = useState("max");
  console.log(totalReturn)
  const styles = {
    Icon: {
      color: "#ffffff",
      fill: "#ffffff",
      fontSize: "40px",
      top: "136px",
      left: "471px",
      width: "40px",
      height: "40px",
    },
  };
  return (
    <div className="main-overview">
      <div className="summary-cards">
        <div className="summary-card">
          <div>
            Deposits injected
            <div
              style={{
                color: "#007aff",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              {totalReturn?.sum_registration_investment_reinjection_usd_approved?.toFixed(
                0
              )}{" "}
              USD{" "}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#007aff",
              borderRadius: "8px",
              opacity: 0.65,
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={styles.Icon} viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z"></path>
            </svg>
          </div>
        </div>

        <div className="summary-card">
          <div>
            Total Monthly Return
            <div
              style={{
                color: "#6a1881",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              {totalReturn?.sum_commission_usd_approved?.toFixed(0)} USD
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#6a1881",
              borderRadius: "8px",
              opacity: 0.65,
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={styles.Icon} viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm.88 15.76V19h-1.75v-1.29c-.74-.18-2.39-.77-3.02-2.96l1.65-.67c.06.22.58 2.09 2.4 2.09.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31 0-.1.01-2.4 2.62-2.96V5h1.75v1.24c1.84.32 2.51 1.79 2.66 2.23l-1.58.67c-.11-.35-.59-1.34-1.9-1.34-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45 0 2.63-2.5 3.13-3.02 3.22z"></path>
            </svg>
          </div>
        </div>
        <div className="summary-card">
          <div>
            Paid
            <div
              style={{
                color: "#286e34",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              {-totalReturn?.sum_payout_usd_approved?.toFixed(0)} USD
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#286e34",
              borderRadius: "8px",
              opacity: 0.65,
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={styles.Icon} viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="m17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.979 5c.043.522.153 1.025.321 1.5h-9.092c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm3.704-.024 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-2.598-3.976c-.328.456-.594.96-.785 1.5h-9.092c-.414 0-.75-.336-.75-.75s.336-.75.75-.75zm7.373-3.25c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"
                fill="nonzero"
              />
            </svg>
          </div>
        </div>
        <div className="summary-card">
          <div>
            Pending Payment
            <div
              style={{
                color: "#38a6b1",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              {-totalReturn?.sum_payout_usd_pending?.toFixed(0)} USD{" "}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#38a6b1",
              borderRadius: "8px",
              opacity: 0.65,
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={styles.Icon} viewBox="0 0 24 24">
              <path d="M18.513 7.119c.958-1.143 1.487-2.577 1.487-4.036v-3.083h-16v3.083c0 1.459.528 2.892 1.487 4.035l3.086 3.68c.567.677.571 1.625.009 2.306l-3.13 3.794c-.936 1.136-1.452 2.555-1.452 3.995v3.107h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794c-.562-.681-.558-1.629.009-2.306l3.087-3.68zm-4.639 7.257l3.13 3.794c.652.792.996 1.726.996 2.83h-12c0-1.104.343-2.039.996-2.829l3.129-3.793c1.167-1.414 1.159-3.459-.019-4.864l-3.086-3.681c-.66-.785-1.02-1.736-1.02-2.834h12c0 1.101-.363 2.05-1.02 2.834l-3.087 3.68c-1.177 1.405-1.185 3.451-.019 4.863z" />
            </svg>
          </div>
        </div>
        <div className="summary-card">
          <div>
            E-Wallet Balance
            <div
              style={{
                color: "#6aa7e6",
                fontSize: "1.2rem",
              }}
            >

              {" "}
              {totalReturn?.sum_commission_usd_pending?.toFixed(0)} USD
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#6aa7e6",
              borderRadius: "8px",
              opacity: 0.65,
              padding: "5px",
              marginLeft: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg style={styles.Icon} viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M18 4H6C3.79 4 2 5.79 2 8v8c0 2.21 1.79 4 4 4h12c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-1.86 9.77c-.24.2-.57.28-.88.2L4.15 11.25C4.45 10.52 5.16 10 6 10h12c.67 0 1.26.34 1.63.84l-3.49 2.93zM6 6h12c1.1 0 2 .9 2 2v.55c-.59-.34-1.27-.55-2-.55H6c-.73 0-1.41.21-2 .55V8c0-1.1.9-2 2-2z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="cards">
        <div className="card">
          <OverviewTileOne />
        </div>
        <div className="card">
          <div className="subtitle">Payout overview</div>

          <PieChart
            colors={["#7CB9E8", "#002D62"]}
            series={[
              {
                data: [
                  {
                    id: 1,
                    value: -totalReturn?.sum_payout_usd_approved,
                    label: "Approved (USD)",
                  },
                  {
                    id: 2,
                    value: -totalReturn?.sum_payout_usd_pending,
                    label: "Pending (USD)",
                  },
                ],
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },

                innerRadius: 60,
                outerRadius: 120,
                paddingAngle: 2,
                cornerRadius: 2,
              },
            ]}
          />
        </div>
        <div className="card chart-div">
          <div
            className="subtitle-and-dropdown"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="subtitle">Joinings</div>

            <div style={{ marginLeft: "auto" }}>
              <Select
                defaultValue="max"
                onChange={(e, newValue) => handleChange(newValue)}
              >
                <Option value="1">One month</Option>
                <Option value="6">Six months</Option>
                <Option value="max">Max</Option>
              </Select>
            </div>
          </div>

          {xLabels && xLabels.length > 0 && (
            <LineChart
              xAxis={[{ scaleType: "point", data: xLabels }]}
              series={[{ data: uData, id: "uvId", color: "#236382" }]}
              grid={{ vertical: true, horizontal: true }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
