import { API } from "../api-end-point";
import { usePostData } from "../api-service/usePostData";

export const useAddNewsLetter = ({ callBackOnSuccess }) =>
  usePostData({
    hideSuccessToast: true,
    endpoint: API.createNewsLeteter,
    queryKeysToInvalidate: [["newsletter"]],
    callBackOnSuccess,
  });
