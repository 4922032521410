import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListMonthlyReturn = () => {
  return useReadData({
    queryKey: [["monthlyReturns"]],
    endpoint: API.monthlyReturn,
  });
};

export { useListMonthlyReturn };
