import "./add-newsletter.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAddNewsLetter } from "../../api/transactions/use-add-newsletter.js";
import { Option, Select } from "@mui/joy";
import { Editor } from "@tinymce/tinymce-react";

function AddNewsletter() {
  const [value, setValue] = useState("");
  const [text, setText] = useState("");

  const onEditorInputChange = (newValue, editor) => {
    setValue(newValue);
    setText(editor.getContent());
  };
  const { mutate: addLetter } = useAddNewsLetter({
    callBackOnSuccess: () => {
      toast.success("Newsletter sent");
      window.location.reload();
    },
  });
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">Newsletter </div>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            gap: "10px",
            alignItems: "stretch",
            padding: "20px",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.target);
            formData.append("content", text);
            addLetter(formData);
          }}
        >
          <div>Newsletter Type</div>

          <Select
            required
            variant="soft"
            name="notification_type"
            defaultValue="ANNOUNCEMENTS"
          >
            <Option value="ANNOUNCEMENTS">Announcements</Option>
            <Option value="CAMPAIGN">Campaign</Option>
            <Option value="SYSTEM_UPDATES">System updates</Option>
            <Option value="WALLETS">Wallets</Option>
          </Select>
          <div>Subject</div>
          <Input required variant="soft" name="subject" />
          <div>Content</div>
          {/* <Textarea required name="content" variant="soft" /> */}

          <Editor
            apiKey={"73yojgvke9wgudkwh6p9756udtmyn3mj3xfcl38nhb2g45qh"}
            onEditorChange={(newValue, editor) =>
              onEditorInputChange(newValue, editor)
            }
            onInit={(evt, editor) => {
              setText(editor.getContent({ format: "text" }));
            }}
            value={value}
          />
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </div>
  );
}

export default AddNewsletter;
