import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListKYCUsers = () => {
  return useReadData({
    queryKey: ["users"],
    endpoint: API.pendingKYCRegistration,
  });
};

export { useListKYCUsers };
