import "./search-bar.css"
import * as React from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import classnames from 'classnames';
import {Link} from  "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
const InputElement = styled('input')(
    ({ theme }) => `
    min-width: 200px;
    width:100%;
    height: 48px;
    padding: 0px 8px;
    margin-left: 10px;
    border: 0;
    box-sizing: border-box;
    border-radius: 24px;
    opacity: 0.5;
    background-color: #c2c2c2;
    font-weight: 500;
    line-height: 24px;
    outline: none;`,
  );
function SearchBar(props){
  const navigate = useNavigate();
  const styles = {
    Icon: {
      color: "#ffffff",
      fill: "#ffffff",
      fontSize: "2rem",
    },
  };
  const options = {
    'Home': '/',
    'Dashboard': '/',
    'Register': '/register',
    'kyc approve': '/kyc-approve',
    'profile approve': '/profile-approve',
    'e-wallet': '/e-wallet',
    'payout': '/payout',
    'members list': '/members-list',
    'newsletter': '/newsletter',
    'monthly return': '/monthly-return',
  };

  // Convert the keys to an array for the autocomplete component
  const optionKeys = Object.keys(options);

  const handleOptionSelect = (event, value) => {
    if (value && options[value]) {
      // Redirect the user to the selected page link
      window.location.href = options[value];
    }
  };
  return (
      <div className={classnames("search-bar-main" ,props.styleName)}>
<Autocomplete
      freeSolo
      options={optionKeys}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search..."
          variant="outlined"
          sx={{
            minWidth: '30vw',
            width: '100%',
            height: '48px',
            padding: '0px 8px',
            marginLeft: '10px',
            border: 0,
            boxSizing: 'border-box',
            borderRadius: '24px',
            opacity: 0.5,
            backgroundColor: '#c2c2c2',
            fontWeight: 500,
            lineHeight: '24px',
            outline: 'none',
            '& .MuiOutlinedInput-root': {
              padding: '0 8px', // Adjust padding inside the input
              '& fieldset': {
                border: 'none', // Removes border around the input field
              },
            },
          }}
        />
      )}
    /> 
    <Button onClick={function(){
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    navigate('/sign-in');

    }} variant="text"><LoginIcon  style={styles.Icon}  /></Button>
    </div>
      );

}

export default SearchBar;