import "./desktop-menu.css"
import * as React from 'react';
import Logo from '../Logo/logo.js';
import classnames from 'classnames';
import List from '@mui/joy/List';
import Box from '@mui/joy/Box';
import ListItem from '@mui/joy/ListItem';
import Home from '@mui/icons-material/Home';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import {Link, useMatch, useResolvedPath} from  "react-router-dom";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WalletIcon from '@mui/icons-material/Wallet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmailIcon from '@mui/icons-material/Email';
import Groups2Icon from '@mui/icons-material/Groups2';
import InventoryIcon from '@mui/icons-material/Inventory';
import PieChartIcon from '@mui/icons-material/PieChart';
const styles = {
    Contact: {
      color: '#236382',
      fill: '#236382',
      fontSize: '46px',
      top: '884px',
      left: '182px',
      width: '46px',
      height: '51px',
    },
  };
function IsLinkActive(to){
  const resolvedPath=useResolvedPath(to);
  const isActive = useMatch({path:resolvedPath.pathname,end:"true"})
  return isActive
}

function DesktopMenu(props){
    
    return (
        <div id="main-menu-div" className={classnames(props.styleName)}>
          <div style={{margin:"15px 20px",marginRight:"30px" }}><Logo /></div>
          <div className='menu-category'>

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              flexWrap: 'wrap',
              // '& > *': { minWidth: 0, flexBasis: 200 },
            }}
          >
                <List
                  size="lg"
                  variant="plain"
                  sx={{
                    maxWidth: 300,
                    borderRadius: 'sm',
                  }}
                  style={{padding:"0 0.5rem"}}
                >
                  <ListItem >
                  <Link to="/" style={{width:"100%"}} className="formatted-link">
                    
                      <ListItemDecorator >
                        <Home style={{fill:"white"}}/>
                      </ListItemDecorator>
                      Dashboard
                    </Link>
                    
                  </ListItem>
                  
                  <ListItem style={{display:"block"}}>
                  <Link to="/register" style={{width:"100%"}} className="formatted-link">
                        <ListItemDecorator>
                          <PersonAddIcon style={{fill:"white"}}/>
                        </ListItemDecorator>
                        Users
                       
                    </Link>
                        <Link style={{marginLeft:"20px"}} to="/register" className="formatted-link">Register</Link>
                        <Link style={{marginLeft:"20px"}}  to="/kyc-approve" className="formatted-link">KYC approve</Link>
                        <Link style={{marginLeft:"20px"}}  to="/profile-approve" className="formatted-link">Profile approve</Link>
                  </ListItem>
                  
                  <ListItem>
                  <Link to="/e-wallet" style={{width:"100%"}} className="formatted-link">
                    
                          <ListItemDecorator>
                            <WalletIcon style={{fill:"white"}}/>
                          </ListItemDecorator>
                          E-Wallet
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/payout" style={{width:"100%"}} className="formatted-link">
                        <ListItemDecorator>
                          <AssessmentIcon style={{fill:"white"}}/>
                        </ListItemDecorator>
                          Payout
                    </Link>
                  </ListItem>

                  {/* <ListItem>
                    <Link to="/resources" style={{width:"100%"}} className="formatted-link">
                          <ListItemDecorator>
                            <BusinessCenterIcon style={{fill:"white"}}/>
                          </ListItemDecorator>
                          Resources
                    </Link>
                  </ListItem> */}

                  <ListItem>
                    <Link to="/members-list" style={{width:"100%"}} className="formatted-link">
                   
                      <ListItemDecorator>
                        <Groups2Icon style={{fill:"white"}}/>
                      </ListItemDecorator>
                      Members list
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link to="/Commission" style={{width:"100%"}} className="formatted-link">
                    
                          <ListItemDecorator>
                            <InventoryIcon style={{fill:"white"}}/>
                          </ListItemDecorator>
                          Commission
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/newsletter" style={{width:"100%"}} className="formatted-link">
                    
                          <ListItemDecorator>
                            <EmailIcon style={{fill:"white"}}/>
                          </ListItemDecorator>
                          Newsletter
                    </Link>
                  </ListItem>

                  
                  <ListItem>
                    <Link to="/monthly-return" style={{width:"100%"}} className="formatted-link">
                    
                          <ListItemDecorator>
                            <PieChartIcon style={{fill:"white"}}/>
                          </ListItemDecorator>
                          Monthly return
                    </Link>
                  </ListItem>
                </List>
          </Box>
          </div>

        </div>
      );

}
  
export default DesktopMenu;