import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListCustomJoiningsDaily = () => {
  return useReadData({
    queryKey: [["monthlyDaily"]],
    endpoint: API.customJoiningsDaily,
  });
};

export { useListCustomJoiningsDaily };
