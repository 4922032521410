import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useListUsers = () => {
  return useReadData({
    queryKey: ["users"],
    endpoint: API.pendingRegistration,
  });
};

export { useListUsers };
