import "./members-list.css";
import toast, { Toaster } from "react-hot-toast";
import SearchBar from "../../components/search-bar/search-bar.js";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import React, { useState } from "react";
import { useMembersList } from "../../api/members-list/use-list-member-list.js";
import { useEditMemberList } from "../../api/members-list/use-edit-member-list.js";

function MembersList() {
  const { data: membersList } = useMembersList();
  const [filterUserName, setFilterUserName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [userId, setUserId] = useState(0);

  const { mutate: updateUser, isPending } = useEditMemberList({
    id: userId,
    // callBackOnSuccess:()=>{
    // }
  });


  const filteredMembers = membersList?.filter((row) => {
    const matchesUserName = row.username
      .toLowerCase()
      .includes(filterUserName.toLowerCase());
    const matchesEmail = row.email
      .toLowerCase()
      .includes(filterEmail.toLowerCase());
    return matchesUserName && matchesEmail;
  });

  const handleDelete = async (id) => {
        setUserId(id)
        updateUser({
          "is_active":false,
          "profile":{
          "profile_active" : false
      }})
  };

  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">Members list</div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            alert(JSON.stringify(formJson));
          }}
        >
          <Stack spacing={1} direction="row" style={{ marginBottom: "10px" }}>
            <Input
              placeholder="Username"
              value={filterUserName}
              onChange={(e) => setFilterUserName(e.target.value)}
            />
            <Input
              placeholder="E-mail"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            />

            <Button
              onClick={() => {
                setFilterEmail("");
                setFilterUserName("");
                toast.success("Filters cleared");
              }}
            >
              Reset
            </Button>
          </Stack>
        </form>

        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="manually-styled-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>First name</th>
                <th>Last name</th>
                <th> E-mail</th>
                <th>Phone number</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers?.map((row) => (
                <tr key={row.id}>
                  <td>{row.username}</td>
                  <td>{row.first_name}</td>
                  <td>{row.last_name}</td>
                  <td>{row.email}</td>
                  <td>{row.profile.phone_number}</td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        size="sm"
                        variant="soft"
                        color="danger"
                        onClick={() => handleDelete(row.id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MembersList;
