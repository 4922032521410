import "./sign-in.css";
import Input from "@mui/joy/Input";
import Logo from "../../components/Logo/logo";
import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUser } from "../../api/auth/use-login-user";

function SignIn(props) {
  const navigate = useNavigate(); // Get the navigate function
  const { mutate: loginUser, isPending } = useLoginUser({
    callBackOnSuccess: () => {
      navigate("/");
    },
  });

  return (
    <div className="main-signin">
      <div className="floaty-middle">
        <div>
          <Logo />
          <div style={{ fontSize: "3rem", fontWeight: "bold" }}>
            Welcome back!
          </div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>Sign In</div>
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);

            loginUser(formData);
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div>Username </div>
            <Input
              style={{ width: "100%" }}
              placeholder=""
              variant="soft"
              name="username"
            />
            <div>Password</div>
            <Input
              style={{ width: "100%" }}
              placeholder=""
              type="password"
              variant="soft"
              name="password"
            />

            <br></br>
            <Button
              style={{
                padding: "10px 25px",
                marginLeft: "auto",
                fontSize: "1.2rem",
              }}
              type="submit"
              color="neutral"
              variant="soft"
            >
              Sign In
            </Button>
          </Stack>
        </form>
        <Link className="real-link" to="/forgot-password">
          forgot password?
        </Link>
      </div>
    </div>
  );
}

export default SignIn;
