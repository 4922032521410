import "./App.css";
import { Routes, Route } from "react-router-dom";
import MobileMenu from "./components/mobile-menu/mobile-menu.js";
import DesktopMenu from "./components/desktop-menu/desktop-menu.js";
import HomeOverview from "./pages/home-overview/home-overview.js";
import Payout from "./pages/payout/payout.js";
import Approve from "./pages/approve/approve.js";
import SignUp from "./pages/sign-up/sign-up.js";
import EWallet from "./pages/e-wallet/e-wallet.js";
import SignIn from "./pages/sign-in/sign-in.js";
import ForgotPassword from "./pages/forgot-password/forgot-password.js";
import MembersList from "./pages/members-list/members-list.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MonthlyReturn from "./pages/monthly-return/monthly-return.js";
import Commission from "./pages/commission/commission.js";
import KYCApprove from "./pages/kyc-approve/kyc-approve.js";
import Newsletter from "./pages/newsletter/newsletter.js";
import useAuth from "./api/auth/use-ensure-cred.js";
import ResetPassword from "./pages/reset-password/reset-password.js";
import PageNotFound from "./pages/page-not-found/page-not-found.js";
import AddNewsletter from "./pages/add-newsletter/add-newsletter.js";
function App() {
  useAuth();
  return (
    <div className="App">
      {/* <CookieConsent debug={true}>This website uses cookies to enhance the user experience.</CookieConsent> */}

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <HomeOverview />
            </>
          }
        />
        <Route
          exact
          path="/kyc-approve"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <KYCApprove />
            </>
          }
        />
        <Route
          exact
          path="/profile-approve"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <Approve />
            </>
          }
        />
        <Route
          exact
          path="/register"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <SignUp />
            </>
          }
        />
        <Route
          exact
          path="/payout"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <Payout />
            </>
          }
        />
        <Route
          exact
          path="/e-wallet"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <EWallet />
            </>
          }
        />

        <Route
          exact
          path="/members-list"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <MembersList />{" "}
            </>
          }
        />
        <Route
          exact
          path="/monthly-return"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <MonthlyReturn />{" "}
            </>
          }
        />
        <Route
          exact
          path="/commission"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <Commission />{" "}
            </>
          }
        />
        <Route
          exact
          path="/newsletter"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <Newsletter />{" "}
            </>
          }
        />
        <Route
          exact
          path="/newsletter/add"
          element={
            <>
              <MobileMenu styleName="mobile" />
              <DesktopMenu styleName="desktop" />
              <AddNewsletter />{" "}
            </>
          }
        />
        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />

        <Route
          exact
          path="/password_reset/:uid/:token"
          element={<ResetPassword />}
        />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
