import React, { useState } from "react";
import "./e-wallet.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import ButtonGroup from "@mui/joy/ButtonGroup";
import DateTimeDisplay from "../../components/date-time-display.js";
import { useListTransactions } from "../../api/transactions/use-list-transactions.js";
import toast from "react-hot-toast";
import { useEditTransaction } from "../../api/transactions/use-edit-transaction.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Divider from '@mui/joy/Divider';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom";
function areEqual(num1, num2) {
  return num1 === num2;
}

function EWallet() {
  const [transactionId, setTransactionId] = useState(-1);
  // Filters
  const [filterStatus, setFilterStatus] = useState("");
  const [filterUserName, setFilterUserName] = useState("");
  const [filterPaymentGateway, setFilterPaymentGateway] = useState("");
  // Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Sorting
  const [sortingKey, setSortingKey] = useState("trans_date")
  const [sortAscending, setSortAscending] = useState(true)

  // Modal

  const [openReject, setOpenReject] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reasonForReject, setReasonForReject] = useState('');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { mutate: updateTransaction, isPending } = useEditTransaction({
    id: transactionId,
    callBackOnSuccess: () => { },
  });
  const handleApprove = async (pk) => {
    setTransactionId(pk);
    updateTransaction({ trans_status: "APPROVED" });
  };
  const handleReject = async () => {
    updateTransaction({ trans_status: "REJECTED", trans_rejection_note: reasonForReject });
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleSortChange = (row) => {
    if (sortingKey != row["field"]) {
      setSortingKey(row["field"]);
      setSortAscending(true);
    } else {
      setSortAscending(!sortAscending)
    }
    setPage(0);
  }
  const columns = [
    { field: "trans_date", headerName: "Date" },
    { field: "username", headerName: "Username" },
    { field: "first_name", headerName: "First name" },
    { field: "last_name", headerName: "Last name" },
    {
      field: "trans_amt_usd",
      headerName: "Payment amount (USD)",
      type: "number"
    },
    { field: "trans_mode", headerName: "Transaction type" },
    { field: "trans_reason", headerName: "Reason" },
    { field: "status", headerName: "Status" },
  ];
  const { data: transactions } = useListTransactions();

  const filteredTransactions = transactions?.filter((row) => {
    const matchesUserName = row.username
      .toLowerCase()
      .includes(filterUserName.toLowerCase());
    const matchesPayment = row.trans_reason
      .toLowerCase()
      .includes(filterPaymentGateway.toLowerCase());
    const matchesStatus = row.trans_status.includes(filterStatus);
    return matchesStatus && matchesPayment && matchesUserName;
  }).sort((a, b) => {
    if (sortAscending) {
      if (a[sortingKey] < b[sortingKey]) return -1;
      if (a[sortingKey] > b[sortingKey]) return 1;
      return 0;
    }
    else {
      if (a[sortingKey] < b[sortingKey]) return 1;
      if (a[sortingKey] > b[sortingKey]) return -1;
      return 0;
    }
  });
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">E-Wallet </div>
        <Stack spacing={1} direction="row" style={{ marginBottom: "10px" }}>
          <Input
            placeholder="Username"
            value={filterUserName}
            onChange={(e) => setFilterUserName(e.target.value)}
          />
          <Input
            placeholder="Reason"
            value={filterPaymentGateway}
            onChange={(e) => setFilterPaymentGateway(e.target.value)}
          />
          <Button
            onClick={() => {
              setFilterUserName("");
              setFilterPaymentGateway("");
              toast.success("Filters cleared");
            }}
          >
            Reset
          </Button>
        </Stack>

        <ButtonGroup
          style={{
            backgroundColor: "white",
            marginBottom: "10px",
            width: "fit-content",
          }}
          variant="plain"
          aria-label="plain button group"
        >
          <Button
            onClick={() => setFilterStatus("")}
            variant={areEqual("", filterStatus) ? "solid" : "plain"}
          >
            All
          </Button>
          <Button
            onClick={() => setFilterStatus("PENDING")}
            variant={areEqual("PENDING", filterStatus) ? "solid" : "plain"}
          >
            Requests
          </Button>
          <Button
            onClick={() => setFilterStatus("APPROVED")}
            variant={areEqual("APPROVED", filterStatus) ? "solid" : "plain"}
          >
            Approved
          </Button>
          <Button
            onClick={() => setFilterStatus("REJECTED")}
            variant={areEqual("REJECTED", filterStatus) ? "solid" : "plain"}
          >
            Declined
          </Button>
        </ButtonGroup>

        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="manually-styled-table"
          >
            <TableHead>
              <TableRow>

                <TableCell>
                </TableCell>
                {columns.map((row) => {
                  return (
                    <TableCell onClick={() => {
                      handleSortChange(row)
                    }}>
                      {sortingKey === row["field"] && !sortAscending ? "  ↑  " : "  "}
                      {sortingKey === row["field"] && sortAscending ? "  ↓  " : "  "}
                      {row.headerName}
                    </TableCell>
                  )
                })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTransactions
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      // hover
                      tabIndex={-1}
                      key={row.code}

                    >
                      <TableCell onClick={() => handleRowClick(row)}>
                        <LaunchIcon />
                      </TableCell>
                      <TableCell>
                        <DateTimeDisplay apiDateTime={row.trans_date} />
                      </TableCell>
                      <TableCell>{row.username} </TableCell>
                      <TableCell>{row.first_name} </TableCell>
                      <TableCell>{row.last_name} </TableCell>
                      <TableCell>{row.trans_amt_usd} USD</TableCell>
                      {/* <TableCell>{row.trans_amt_btc} BTC</TableCell> */}
                      <TableCell>{row.trans_mode}</TableCell>
                      <TableCell>{row.trans_reason}</TableCell>
                      {/* <TableCell>{row.balance}</TableCell> */}
                      <TableCell
                        className={
                          areEqual("PENDING", row.trans_status) ? "hidden" : ""
                        }
                      >
                        {row.trans_status}
                      </TableCell>
                      <TableCell
                        className={
                          areEqual("PENDING", row.trans_status) ? "" : "hidden"
                        }
                      >
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="sm"
                            variant="soft"
                            color="neutral"
                            onClick={() => {
                              setTransactionId(row.pk);
                              handleApprove(row.pk);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="sm"
                            variant="soft"
                            color="danger"
                            onClick={() => {
                              setTransactionId(row.pk);
                              setOpenReject(true);
                            }}
                          >
                            Reject
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filteredTransactions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Transaction #{selectedRow?.pk}
          </Typography>

          <Divider />
          <Typography id="modal-desc" textColor="text.tertiary">
            <Table aria-label="basic table">
              <tbody>
                <tr>
                  <th style={{ textAlign: "left" }}>User name</th>
                  <td>:</td>
                  <td>{selectedRow?.username}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>First name</th>
                  <td>:</td>
                  <td>{selectedRow?.first_name}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Last name</th>
                  <td>:</td>
                  <td>{selectedRow?.last_name}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>E-walltet balance</th>
                  <td>:</td>
                  <td>{selectedRow?.balance} BTC</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Start date</th>
                  <td>:</td>
                  <td>
                    <DateTimeDisplay apiDateTime={selectedRow?.trans_date} />
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Last modification date</th>
                  <td>:</td>
                  <td>
                    <DateTimeDisplay
                      apiDateTime={selectedRow?.last_update_date}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction amount BTC</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_amt_btc} BTC</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction amount USD</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_amt_usd} USD</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction mode</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_mode}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction reason</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_reason}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction type</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_type}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction note</th>
                  <td>:</td>
                  <td>{selectedRow?.note}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction proof</th>
                  <td>:</td>
                  <td>
                    {selectedRow?.trans_picture ? (
                      <Link
                        level="body-sm"
                        href={selectedRow.trans_picture}
                        target="_blank"
                      >
                        {" "}
                        Transaction proof
                      </Link>
                    ) : (
                      "No file provided"
                    )}

                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left" }}>Transaction status</th>
                  <td>:</td>
                  <td>{selectedRow?.trans_status}</td>
                </tr>
                {selectedRow?.trans_status == "REJECTED" && (

                  <tr>
                    <th style={{ textAlign: "left" }}>Rejection reason</th>
                    <td>:</td>
                    <td>{selectedRow?.trans_rejection_note}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Typography>
        </Sheet>
      </Modal>


      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openReject}
        onClose={() => setOpenReject(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Reject transaction?
          </Typography>
          Please input the reason for this rejection
          <Input
            placeholder="Type in here…"
            onChange={(e) => {
              setReasonForReject(e.target.value);
            }}
          />
          <br />
          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}
          >
            <Button
              size="sm"
              variant="soft"
              color="primary"
              onClick={() => {
                setOpenReject(false);
                setReasonForReject("");
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="soft"
              color="danger"
              onClick={() => {
                setOpenReject(false);
                handleReject();
              }}
            >
              Reject
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </div>
  );
}

export default EWallet;
