import './home-overview.css';
import SearchBar from '../../components/search-bar/search-bar.js';
import Overview from '../../components/overview/overview.js';
function HomeOverview(){
    return(
        <div className="main-content">
          
        <SearchBar styleName="desktop-search"/>
        <Overview/>
      </div>
    );
};


export default HomeOverview;