import { API } from "../api-end-point";
import { useReadData } from "../api-service/useReadData";

const useCompanyInterestList = () => {
  return useReadData({
    queryKey: [["comapnyInterest"]],
    endpoint: API.companyInterest,
  });
};

export { useCompanyInterestList };
