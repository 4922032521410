import React from 'react';

function DateTimeDisplay({ apiDateTime ,length}) {
  // Convert the date string into a Date object
  const date = new Date(apiDateTime);
  let formattedDate=null
  if (length==null || length=="long") {
  // Format the date and time nicely using toLocaleString
   formattedDate = date.toLocaleString('en-US', {
    // weekday: 'long', // e.g., "Saturday"
    year: 'numeric', // e.g., "2024"
    month: 'long',   // e.g., "October"
    day: 'numeric',  // e.g., "5"
    hour: '2-digit', // e.g., "08"
    minute: '2-digit', // e.g., "33"
    second: '2-digit', // e.g., "32"
    hour12: true, 
  })
  }else{
     formattedDate = date.toLocaleString('en-US', {
      // weekday: 'long', // e.g., "Saturday"
      year: 'numeric', // e.g., "2024"
      month: 'long',   // e.g., "October"
      day: 'numeric',  // e.g., "5"
      // hour: '2-digit', // e.g., "08"
      // minute: '2-digit', // e.g., "33"
      // second: '2-digit', // e.g., "32"
      hour12: true, // for 12-hour time format (use false for 24-hour)

  })
  };

  return (
    <div>
      {formattedDate}
    </div>
  );
}


export default DateTimeDisplay;