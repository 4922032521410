import React, { useState, useEffect } from 'react';

import { useListUsersCommission } from '../../api/users/use-list-users-commission.js';

export default function UserSelectList({sendDataToParent}){
  const { data: initialUsers } = useListUsersCommission();
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedUsers, setSelectedUsers]=useState([]);

  useEffect(() => {
    if (initialUsers) {
      setUsers(initialUsers.map(user => ({ ...user, selected: true })));
    }
  }, [initialUsers]);
  const handleUserChange = (id) => {
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === id ? { ...user, selected: !user.selected } : user
      )
    );
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setUsers(users?.map(user => ({ ...user, selected: newSelectAll })));
  };
 useEffect(() => {
    sendDataToParent(users
      .filter(user => user.selected)
      .map(user => user.id),selectAll);
  }, [users]);
  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={selectAll}
          onChange={()=>{handleSelectAll();}}
        />
        Select All Users
      </label>
      {!selectAll && ( <ul style={{ padding: 0, margin: 0 }}>
        {users?.map(user => (
          <li style={{ listStyleType: 'none', padding: 0, margin: 0 }} key={user.id}>
            <label>
              <input
                type="checkbox"
                checked={user.selected}
                onChange={() => {handleUserChange(user.id)}}
              />
              {user.username}: {user.profile.first_name}, {user.profile.last_name}
            </label>
          </li>
        ))}
      </ul> )}
    </div>
  );
}